import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//var Recaptcha = require('react-recaptcha');

/*var reCaptchaCallback = function (response) {
    //alert(response);
  };*/

export default class Contact extends React.Component {
    state = {
        name : "",
        email : "",
        content : "",
        recaptcha: "",
        toastMessage: "",
        status: "waiting",
        url: 'https://d0y58u3jqh.execute-api.us-west-2.amazonaws.com/master/email/send',
    }
    handleClear = event => {
        this.setState({
            name: "",
            email: "",
            content: "",
            status: "waiting",
            toastMessage: "",
          })
        
    }
    handleBlur = event => {
        
    }
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
    
        this.setState({
          [name]: value,
        })
      }

      handleSubmit = event => {
          if(this.state.status ==="waiting"){
                postMessage = this.state
                this.setState({
                    status: "sending"
                })
                delete postMessage.toastMessage
                delete postMessage.url
                if (postMessage.recaptcha === ""||postMessage.recaptcha==null)
                {
                    this.setState({
                        toastMessage: "Please solve the reCAPTCHA challenge above."
                    });
                    toast("Please solve the reCAPTCHA challenge");
                } else {
                    /*this.setState({
                        toastMessage: JSON.stringify(postMessage)
                        
                    })*/
                    
                    fetch('https://d0y58u3jqh.execute-api.us-west-2.amazonaws.com/master/email/send',{
                        method: "POST",
                        body: JSON.stringify(postMessage),
                    }).then(response => {
                        console.log(response);
                        if (response.status == 200) {
                            this.setState({
                                name: "",
                                email: "",
                                content: "",
                                status: "waiting",
                                toastMessage: "Thank you for contacting us."
                            })
                            toast("Submitted Successfully");
                            //ToDo:  Either redirect to a success page or do something to make success or failure more obvious.
                            //ToDo:  The Try/Catch is not catching the submission failure and response is a promise when it fails, need to figure out how to detect a 500
                            //ToDo:  Maybe disable the submit button and turn it back on after a successful submission or failure
                        }
                        
                    }).catch(error => {
                        this.setState({status: "waiting", toastMessage: "Failed to submit"})
                        toast("Failed to submit message");    
                    }
                        );
                    
                }
          } else {
            toast("Submission already in progress");
          }
          
        event.preventDefault()
      }
      handleRecaptcha = value => {
        this.setState({ "recaptcha": value });
      };
    render() {
      return (
    <section id="contact">
        <div className="inner">
            <section>
                <form method="post" action="#" onSubmit={this.handleSubmit}>
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                        required
                        />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        required
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="content" id="content" rows="6"
                        value={this.state.content}
                        onChange={this.handleInputChange}
                        required
                        ></textarea>
                    </div>
                    <ReCAPTCHA
                        sitekey="6Lf0u6MUAAAAAIZLz7A8Plzq20D8gWw7aRM0bAwB"
                        onChange={this.handleRecaptcha}
                        />
                    <ToastContainer />
                    <div id="toasty">
                        <p>{this.state.toastMessage}</p>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Send Message" className="special" /></li>
                        <li><input type="reset" value="Clear" onClick={this.handleClear} /></li>
                    </ul>
                </form>
                
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:WebContact@teachersplusfund.org">WebContact@teachersplusfund.org</a>
                    </div>
                </section>
                {/*<section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <span>(000) 000-0000 x12387</span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>1234 Somewhere Road #5432<br />
                        Nashville, TN 00000<br />
                        United States of America</span>
                    </div>
                </section>*/}
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value="QK7B8BWS7CVLG" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                    </form>
                    </h3>
                    </div>
                </section>
            </section>
        </div>
    </section>
 )
}
}

