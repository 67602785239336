import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic03 from '../assets/images/pic03.jpg'
import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Elements = (props) => (
    <Layout>
        <Helmet>
            <title>2019 Fundraising Goal OK</title>
            <meta name="description" content="Elements Page" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>2019 Fundraising Goal</h1>
                    </header>
                    <div className="grid-wrapper">
                        <div className="col-9">
                            <p>
                                We are a new non-profit company actively trying to raise 
                                $10,000 by the end of the year to donate $500 to as many Oklahoma teachers as possible by Christmas.

                            </p>
                            <p>Let's show teachers that we all support them by donating to Teachers Plus Fund.
                            </p>
                            <p>
                                If everyone in the state donated two dollars per month for the rest of the year, 
                                we could give $500 and then some to every teacher in the state.  Donate what you are comfortable with, everything counts.  
                            </p>
                        </div>
                        <div className="col-3">
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick" />
<input type="hidden" name="hosted_button_id" value="QK7B8BWS7CVLG" />
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
</form>
                        </div>
                    </div>


                </div>
            </section>

        </div>

    </Layout>
)

export default Elements